<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Edit Category"
      ok-title="Edit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label=""
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <br />
          <v-select
            v-model="subcategory_category"
            id="categoryList"
            placeholder="Category"
            :options="categories"
            required
          />
          <br />
          <b-form-input
            id="name-input"
            v-model="toEdit.label"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Ajouter sous catégories</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="5" class="mb-md-0 mb-2">
            <v-select
              v-model="subcategory_category"
              id="categoryList"
              placeholder="Category"
              :options="categories"
            />
            <p
              style="color: #dc143c; font-size: 11px"
              v-if="
                showcat === true && this.subcategory.categories.length === 0
              "
            >
              <strong>Category is required </strong>
            </p>
          </b-col>
          <b-col cols="12" md="5" class="mb-md-0 mb-2">
            <b-form-input
              v-model="subcategory.label"
              placeholder="Label"
              type="text"
            />
            <p
              style="color: #dc143c; font-size: 11px"
              v-if="showsub === true && this.subcategory.label === ''"
            >
              <strong>Subcategory label is required </strong>
            </p>
          </b-col>

          <b-col cols="12" md="2" class="mb-md-0 mb-2">
            <b-button variant="primary" block @click="_addCategory()">
              Ajouter
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card-actions action-collapse title="Liste des sous catégories">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block mr-1"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex align-items-center">
             <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart"
                @click="deletedSelected()"
              >
                <feather-icon
                   icon="TrashIcon"
                  class=""
                />
              </b-button>
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-category"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.label === 'Label'" class="text-nowrap">
          <span>
            {{ props.row.label }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'categories'" class="text-nowrap">
          <span>
            <div v-for="cat in props.row.categories" :key="cat">
              <div v-for="cat1 in categories" :key="cat1._id">
                <div v-if="cat === cat1._id">
                  <feather-icon :icon="cat1.icon" /> {{ cat1.label }}
                </div>
              </div>
            </div>
          </span>
        </span>
        <span v-else-if="props.column.label === 'Action'" class="text-nowrap">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                id="toggle-btn"
                v-b-modal.modal-prevent-closing
                @click="_editCategory(props.row)"
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="_deleteCategory(props.row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
             <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
    </vue-good-table>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import SubCategoryMixin from "@/mixins/subcategory.mixin";
import CategoryMixin from "@/mixins/category.mixin";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [SubCategoryMixin, CategoryMixin],

  components: {
    vSelect,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BAlert,
    BModal,
    VBModal,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    BPagination,
    BFormSelect,
    BForm,
    BTooltip,
    BCardActions,
  },

  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      showcat: false,
      showsub: false,
      subcategory_category: "",
      name: "",
      nameState: null,
      submittedNames: [],
      label: "",
      toEdit: {},
      subcategory: {
        label: "",
        categories: [],
      },
      columns: [
        {
          label: "Label",
          field: "label",
        },
        {
          label: "catégorie",
          field: "categories",
        },
        {
          label: "Action",
          field: "label",
        },
      ],
      rows: [],
    };
  },

  methods: {
     deletedSelected() {
      console.log("hi selected ",this.$refs['table-category'].selectedRows)
       this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas annuler votre action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: "red",
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          (async () => {
              this.$refs['table-category'].selectedRows.forEach(async (c) => {
                await this.$store.dispatch("DeleteSubCategory", c).then((data) => {
                  this.rows = [];
                  let self = this;
                  setTimeout(function () {
                    self.rows = self.subcategories;
                  }, 2000);
                });
              });
              this.$toast({
                component: ToastificationContent,
                  props: {
                    title: "Successfylly Deleted",
                    icon: "TrashIcon",
                    variant: "success",
                },
              });
            })();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Annuler la suppression ",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      });
    },
      advanceSearch(val) {
      this.searchTerm = val;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.toEdit.categories[0] = this.subcategory_category._id;

      this.$store.dispatch("UpdateSubCategory", this.toEdit).then((data) => {
        // if(data._id!==undefined) {
        this.toEdit = {};
        this.rows = [];
        let self = this;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Edit subcategory success",
            icon: "EditIcon",
            variant: "success",
          },
        });
        setTimeout(function () {
          self.rows = self.subcategories;
        }, 1000);

        /* }else{
             this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'Edit subcategory failed',
                  icon: "EditIcon",
                  variant: 'danger',
                  },
                 })
           }*/
      });
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    _addCategory() {
      if (this.subcategory_category._id !== undefined) {
        if (this.subcategory.categories==undefined) {
          this.subcategory.categories = []
        }
        this.subcategory.categories.push(this.subcategory_category._id);
      }
      if (this.subcategory.label === "") {
        this.showsub = true;
      }
      if (this.subcategory.categories.length === 0) {
        this.showcat = true;
      }

      if (
        this.subcategory.label !== "" &&
        this.subcategory.categories.length > 0
      ) {
        this.$store
          .dispatch("AddSubCategory", this.subcategory)
          .then((data) => {
            if (data === undefined) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Add subcategory failed",
                  icon: "PlusIcon",
                  variant: "danger",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Add subcategory success",
                  icon: "PlusIcon",
                  variant: "success",
                },
              });
              this.$store.dispatch("ListCategories");
            }
            this.subcategory_category = {};
            this.subcategory = {};
          });
      }
    },

    _editCategory(subcategory) {
      this.toEdit = Object.assign({}, subcategory);
      for (let i = 0; i < this.categories.length; i++) {
        if (this.toEdit.categories[0] === this.categories[i]._id) {
          this.subcategory_category = this.categories[i].label;
          break;
        }
      }
      console.log("to edit ", this.subcategory_category);
    },
    _deleteCategory(subcategory) {
      this.$store.dispatch("DeleteSubCategory", subcategory).then((data) => {
        this.rows = [];
        let self = this;
        setTimeout(function () {
          self.rows = self.subcategories;
        }, 3000);
      });
    },
  },
  mounted() {
    this.rows = this.subcategories;
    console.log("rows ", this.rows);
  },

  watch: {
    subcategories: function () {
      this.rows = [];
      this.rows = this.subcategories;
      console.log("rows 2", this.rows);
    },
    subcategory: function () {
      if (this.subcategory.label !== "") {
        this.showsub = false;
      }
    },
    subcategory_category: function () {
      if (this.subcategory_category === null) {
        this.showcat = true;
      } else if (this.subcategory_category._id !== undefined) {
        this.showcat = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>